import React, { useEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import Api from "@/api/Api";
import ActionVerification from "@/components/actionVerification/ActionVerification";
import Label from "@/components/form/Label";
import ImageVideoUploader from "@/components/image_video_uploader/ImageVideoUploader";
import Modal from "@/components/modal/Modal";
import MemoryDisplay from "@/contexts/memories/modules/MemoryDisplay";
import {
  flashErrorMessage,
  flashMessageFromResponseError,
  flashSuccessMessage
} from "@/utils/alert_utils";
import { buildPath } from "@/utils/api_utils";
import Modeler from "@/utils/modeler/modeler";
import { faArrowsRotate } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "@/components/button/Button";
import ErrorMessage from "@/components/form/ErrorMessage";
import Input from "@/components/form/Input";
import InputGroup from "@/components/form/InputGroup";
import TextEditor from "@/components/form/TextEditor";
import ConfirmationModal from "@/components/modal/ConfirmationModal";
import MemoriesAddLifeThreadModal from "@/contexts/memories/components/MemoriesAddLifeThreadModal";
import MemoriesThemes from "@/contexts/memories/components/MemoriesThemes";
import { idbStore } from "@/queries/persister";
import { route } from "@/utils/route_utils";
import Uppy from "@uppy/core";
import XHRUpload from "@uppy/xhr-upload";
import classNames from "classnames";
const submitEnqueuedMemories = async (attrs) => {
  const records = await idbStore().getAll();
  const memories = records.filter(({ memory }) => !!memory.created_at).sort((a, b) => a.memory.created_at - b.memory.created_at);
  if (memories.length === 0)
    return null;
  flashSuccessMessage("Submitting your memories...");
  const paths = memories.map(async ({ memory }) => {
    memory = { ...memory, ...attrs };
    if (memory.memoryImages && memory.memoryImages.length > 0) {
      const uppy = new Uppy().use(XHRUpload, { endpoint: "/upload" });
      memory.memoryImages.forEach(({ blob }) => uppy.addFile(blob));
      const { successful: uploadedImages, failed } = await uppy.upload();
      if (failed.length > 0) {
        console.error("Failed to upload images", failed);
      }
      memory.memory_images_attributes = uploadedImages.map((file) => ({
        image: JSON.stringify(file.response.body.data)
      }));
    }
    const { redirect_path } = await submitMemory({ memory }, true);
    return redirect_path;
  });
  try {
    return await Promise.all(paths).then((p) => p.pop());
  } catch (err) {
    flashErrorMessage("Failed to submit memories");
    return null;
  }
};
const submitMemory = async (formState, cached = false) => {
  try {
    const { data } = await Api.utility.post(route("memories"), formState);
    await idbStore().removeItem([formState.memory.project_id, "memory"]);
    if (data.redirect_path) {
      const { memory: memoryData } = formState;
      const memoryType = memoryData.memory_video_attributes ? "video" : memoryData.memory_images_attributes && memoryData.memory_images_attributes.length > 0 ? "photo" : "text";
      mixpanel.track("memory_created", {
        kind: "project",
        memory_id: data.memory.data.id,
        project_id: memoryData.project_id,
        type: memoryType,
        cached
      });
    }
    return data;
  } catch (err) {
    if (err.response)
      flashMessageFromResponseError(err);
  }
};
const MemoriesNew = (props) => {
  const { groups, project, project_themes, user, userData, uploadPath } = props;
  const { t } = useTranslation();
  const projectUser = new Modeler(userData).build();
  const initialFormState = {
    description: "",
    email: "",
    first_name: "",
    last_name: "",
    relationship_description: "",
    relationship_types: [],
    text: "",
    themes: [],
    video_thumbnail_url: "",
    video_url: ""
  };
  const [form, setForm] = useState(initialFormState);
  const formRef = useRef(null);
  formRef.current = form;
  const [descriptionLength, setDescriptionLength] = useState(0);
  const [hasCachedMemory, setHasCachedMemory] = useState();
  const [isConfirmingClearMemory, setIsConfirmingClearMemory] = useState(false);
  const [isSubmittingNewMemory, setIsSumbittingNewMemory] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isAddLifeThreadModalVisible, setIsAddLifeThreadModalVisible] = useState(false);
  const [newLifeThreadName, setNewLifeThreadName] = useState("");
  const [viewMode, setViewMode] = useState("both");
  const [themes, setThemes] = useState(project_themes);
  const [previewImages, setPreviewImages] = useState([]);
  const [onBehalfConfirmation, setOnBehalfConfirmation] = useState(false);
  const uppyUploadRef = useRef(null);
  useEffect(() => {
    const initializeFormState = async () => {
      var _a, _b, _c;
      try {
        const { memory } = await idbStore().getItem([project.id, "memory"]) || {};
        if (memory) {
          setHasCachedMemory(true);
          setForm({
            text: (_a = memory.memory_quote_attributes) == null ? void 0 : _a.text,
            video_url: ((_b = memory.memory_video_attributes) == null ? void 0 : _b.video_url) || "",
            video_thumbnail_url: ((_c = memory.memory_video_attributes) == null ? void 0 : _c.video_thumbnail_url) || "",
            ...memory
          });
          if (memory.memory_images_attributes.length === 0 && memory.memory_video_attributes) {
            setViewMode("video");
          }
          const span = document.createElement("span");
          span.innerHTML = memory.description;
          setDescriptionLength(span.innerText.length);
          setPreviewImages(memory.memoryImages || []);
        } else {
          setHasCachedMemory(false);
        }
      } catch (error) {
        console.error("Error initializing form state", error);
      }
    };
    initializeFormState();
  }, []);
  useEffect(() => {
    mixpanel.track("memory_started", {
      kind: "project",
      project_id: project.id
    });
  }, []);
  useEffect(() => {
    const isInitialFormState = JSON.stringify(form) === JSON.stringify(initialFormState);
    if ((previewImages == null ? void 0 : previewImages.length) === 0 && (hasCachedMemory === null || isInitialFormState))
      return;
    idbStore().setItem([project.id, "memory"], formatBody());
  }, [form, previewImages]);
  const closeModal = () => {
    setIsModalVisible(false);
  };
  const formatBody = (images = []) => {
    const { text, video_thumbnail_url, video_url, ...formFields } = formRef.current;
    const formattedImages = images.map(({ jsonData }) => ({ image: jsonData }));
    const formattedThemes = formFields.themes.map(({ id }) => id);
    return {
      memory: {
        ...formFields,
        project_id: project.id,
        user_id: user ? user.id : "",
        memory_images_attributes: formattedImages,
        memory_quote_attributes: text ? { text } : null,
        memory_video_attributes: video_url ? { video_url, video_thumbnail_url } : null,
        theme_ids: formattedThemes,
        is_on_behalf: formFields.first_name.length > 0,
        updated_at: /* @__PURE__ */ new Date()
      }
    };
  };
  const handleOnClick = () => {
    setIsModalVisible(true);
  };
  const submitForm = async (images) => {
    const body = formatBody(images);
    try {
      const { redirect_path } = await submitMemory(body);
      const redirectPath = redirect_path || route("project", { id: project.id });
      window.location.href = redirectPath;
    } catch (err) {
      setIsSumbittingNewMemory(false);
    }
  };
  const handleClearCachedMemory = async () => {
    idbStore().removeItem([project.id, "memory"]);
    setForm(initialFormState);
    setHasCachedMemory(false);
    setIsConfirmingClearMemory(false);
    setPreviewImages([]);
    setViewMode("both");
    flashSuccessMessage("Memory Restarted");
  };
  const handleSubmitForm = async () => {
    setIsSumbittingNewMemory(true);
    if (!user) {
      const body = {
        memory: {
          ...formatBody().memory,
          created_at: /* @__PURE__ */ new Date(),
          memoryImages: previewImages
        }
      };
      await idbStore().setItem([project.id, "memory"], body);
      window.location.href = route("signUpPage", {
        redirect_path: window.location.pathname
      });
      return;
    }
    if (previewImages && previewImages.length > 0) {
      uppyUploadRef.current.upload();
    } else {
      submitForm([]);
    }
  };
  const createTheme = async (name) => {
    if ((projectUser == null ? void 0 : projectUser.role) === "admin") {
      const url = `api/v1/themes`;
      try {
        const body = {
          theme: {
            name,
            project_id: project.id
          }
        };
        const response = await Api.utility.post(url, body);
        const newTheme = new Modeler(response.data).build();
        refreshProjectThemes(newTheme);
      } catch (err) {
        flashMessageFromResponseError(err);
      }
    } else {
      return null;
    }
  };
  const refreshProjectThemes = async (newTheme) => {
    const url = buildPath("api/v1/themes", {
      project_id: project.id,
      status: "active",
      order: "asc"
    });
    try {
      const response = await Api.utility.get(url);
      const data = new Modeler(response.data).build();
      addTheme(newTheme);
      setThemes(data);
      const inactiveTheme = form.themes.find(
        (theme) => !data.some((formTheme) => theme.id === formTheme.id)
      );
      if (inactiveTheme) {
        removeTheme(inactiveTheme);
      }
    } catch (err) {
      flashMessageFromResponseError(err);
    }
  };
  const updateField = (field, value) => {
    setForm({
      ...form,
      [field]: value
    });
  };
  const addTheme = (theme) => {
    setForm({
      ...form,
      themes: [...form.themes, theme]
    });
  };
  const removeTheme = (theme) => {
    const entry = form.themes.find((formTheme) => formTheme.id === theme.id);
    const filteredThemes = form.themes.filter(
      (formTheme) => formTheme !== entry
    );
    setForm({
      ...form,
      themes: filteredThemes
    });
  };
  const updateThemes = (selectedThemes) => {
    setForm({
      ...form,
      themes: selectedThemes
    });
  };
  const onChangeVideo = (url, thumbnailUrl) => {
    setForm({
      ...form,
      video_url: url,
      video_thumbnail_url: thumbnailUrl
    });
  };
  const onChangeView = (newView) => {
    setViewMode(newView);
  };
  const onChangeDescription = (value, length, source) => {
    if (source === "user") {
      updateField("description", value);
    }
    setDescriptionLength(length);
  };
  const onChangePreviewImages = (images) => {
    setPreviewImages(images);
    const { memory } = formatBody();
    memory.memoryImages = images;
    idbStore().setItem([project.id, "memory"], { memory });
  };
  const onChangeLifeThread = (lifeThreadName) => {
    setNewLifeThreadName(lifeThreadName);
  };
  const onAddLifeThread = async () => {
    createTheme(newLifeThreadName);
    setIsAddLifeThreadModalVisible(false);
  };
  const onAddLifeThreadModalOnClose = () => {
    setIsAddLifeThreadModalVisible(false);
  };
  const onCustomLifeThreadClicked = () => {
    setNewLifeThreadName("");
    setIsAddLifeThreadModalVisible(true);
  };
  const onBehalfFirstNameValidationMessage = form.first_name.trim() === "" && form.last_name.trim() !== "" ? "Field is required" : null;
  const onBehalfLastNameValidationMessage = form.first_name.trim() !== "" && form.last_name.trim() === "" ? "Field is required" : null;
  const representeeFullNameSet = form.first_name.trim() !== "" && form.last_name.trim() !== "";
  const available = form.themes.length > 0 && (viewMode !== "both" || descriptionLength > 0) && !onBehalfFirstNameValidationMessage && !onBehalfLastNameValidationMessage;
  const previewAvailable = !representeeFullNameSet ? available : available && onBehalfConfirmation;
  const trackMemoryHighlightLink = () => {
    mixpanel.track("view_memory_highlight_example", {
      kind: "project",
      group_name: groups.map((group) => group.slug).join(","),
      project_id: project.id,
      type: "button"
    });
  };
  const trackMemoryShareLink = () => {
    mixpanel.track("view_inspiration_for_memory", {
      kind: "project",
      group_name: groups.map((group) => group.slug).join(","),
      project_id: project.id,
      type: "button"
    });
  };
  return /* @__PURE__ */ React.createElement(React.Fragment, null, hasCachedMemory && /* @__PURE__ */ React.createElement("div", { className: "mx-auto md:max-w-[12rem] mb-4" }, /* @__PURE__ */ React.createElement(
    Button,
    {
      color: "secondary",
      onClick: () => setIsConfirmingClearMemory(true)
    },
    /* @__PURE__ */ React.createElement(FontAwesomeIcon, { icon: faArrowsRotate, className: "mr-2" }),
    "Restart Memory"
  )), (projectUser == null ? void 0 : projectUser.role) === "admin" && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", { className: "columns is-centered is-mobile" }, /* @__PURE__ */ React.createElement("div", { className: "column is-12-mobile is-8-tablet memories-new__onbehalf-label-wrapper px-2" }, /* @__PURE__ */ React.createElement(Label, { labelSize: "small", labelNote: "(Optional)", withoutPadding: true }, /* @__PURE__ */ React.createElement("span", null, "Posting as", " ", /* @__PURE__ */ React.createElement("strong", null, user.first_name, " ", user.last_name), ". As an Admin, you can post this memory on behalf of someone else.")))), /* @__PURE__ */ React.createElement("div", { className: "columns is-centered memories-new____name-wrapper-no-padding-bottom" }, /* @__PURE__ */ React.createElement("div", { className: "column is-12-mobile is-4-tablet" }, /* @__PURE__ */ React.createElement(
    Input,
    {
      placeholder: t(
        "form.placeholder.memoryOnBehalf.firstName"
      ),
      onChange: (value) => updateField("first_name", value),
      value: form.first_name
    }
  ), onBehalfFirstNameValidationMessage && /* @__PURE__ */ React.createElement(ErrorMessage, { message: onBehalfFirstNameValidationMessage })), /* @__PURE__ */ React.createElement("div", { className: "column is-12-mobile is-4-tablet" }, /* @__PURE__ */ React.createElement(
    Input,
    {
      placeholder: t(
        "form.placeholder.memoryOnBehalf.lastName"
      ),
      onChange: (value) => updateField("last_name", value),
      value: form.last_name
    }
  ), onBehalfLastNameValidationMessage && /* @__PURE__ */ React.createElement(ErrorMessage, { message: onBehalfLastNameValidationMessage }))), representeeFullNameSet ? /* @__PURE__ */ React.createElement(
    ActionVerification,
    {
      verificationMessage: t(
        "form.label.memory.memoryOnBehalfConfirmation"
      ),
      changeVerification: setOnBehalfConfirmation,
      isItalic: true
    }
  ) : null), /* @__PURE__ */ React.createElement("div", { className: "columns is-centered is-mobile" }, /* @__PURE__ */ React.createElement("div", { className: "column is-12-mobile is-8-tablet memories-new__share" }, /* @__PURE__ */ React.createElement("h2", null, /* @__PURE__ */ React.createElement(Label, { isBold: true, isRequired: true, labelSize: "large" }, t("form.label.memory.memoryShare"))), /* @__PURE__ */ React.createElement(
    ImageVideoUploader,
    {
      viewMode,
      previewImages,
      video_url: form.video_url,
      project_id: project.id,
      video_thumbnail_url: form.video_thumbnail_url,
      onChangePreviewImages,
      onChangeVideo,
      onChangeView,
      afterUpload: submitForm,
      uppyUploadRef,
      uploadPath
    }
  ))), /* @__PURE__ */ React.createElement("div", { className: "memories-new__section-wrapper" }, /* @__PURE__ */ React.createElement("div", { className: "columns is-centered is-mobile" }, /* @__PURE__ */ React.createElement("div", { className: "column is-12-mobile is-8-tablet form-editor" }, /* @__PURE__ */ React.createElement(
    TextEditor,
    {
      onChange: onChangeDescription,
      value: form.description
    }
  ), /* @__PURE__ */ React.createElement("p", { className: "my-4" }, t("form.description.memory.memoryShare"), /* @__PURE__ */ React.createElement(
    "a",
    {
      className: "link ml-1",
      href: process.env.external_url["memory_prompts"],
      onClick: trackMemoryShareLink,
      target: "_blank"
    },
    t("form.description.memory.memoryShareLink")
  ))))), /* @__PURE__ */ React.createElement("div", { className: "memories-new__section-wrapper" }, /* @__PURE__ */ React.createElement(
    MemoriesThemes,
    {
      createTheme,
      themes: form.themes,
      onAddTheme: addTheme,
      onRemoveTheme: removeTheme,
      onAddNewLifeThread: (projectUser == null ? void 0 : projectUser.role) === "admin" && onCustomLifeThreadClicked,
      project,
      defaultThemes: themes,
      updateThemes,
      user
    }
  )), /* @__PURE__ */ React.createElement("div", { className: "memories-new__section-wrapper" }, /* @__PURE__ */ React.createElement("div", { className: "columns is-centered is-mobile" }, /* @__PURE__ */ React.createElement("div", { className: "column is-12-mobile is-8-tablet" }, /* @__PURE__ */ React.createElement(
    InputGroup,
    {
      description: /* @__PURE__ */ React.createElement("p", { className: "form-label__description" }, /* @__PURE__ */ React.createElement(
        Trans,
        {
          i18nKey: "form.description.memory.memorySnippet",
          values: { preferredName: project.preferred_name }
        },
        /* @__PURE__ */ React.createElement(
          "a",
          {
            className: "link",
            href: process.env.external_url["memory_highlights"],
            onClick: trackMemoryHighlightLink,
            target: "_blank"
          }
        )
      )),
      label: t("form.label.memory.memorySnippet.primary"),
      labelNote: t("form.label.memory.memorySnippet.note"),
      labelSize: "large",
      maxLength: 70,
      onChange: (value) => updateField("text", value),
      value: form.text
    }
  )))), /* @__PURE__ */ React.createElement("div", { className: "columns is-centered is-mobile" }, /* @__PURE__ */ React.createElement("div", { className: "column is-12-mobile is-8-tablet" }, /* @__PURE__ */ React.createElement("div", { className: "columns memories-new__button-wrapper" }, /* @__PURE__ */ React.createElement("div", { className: "column is-12-mobile is-4-tablet" }, /* @__PURE__ */ React.createElement(
    Button,
    {
      color: "secondary",
      isDisabled: !previewAvailable,
      onClick: handleOnClick
    },
    t("form.label.memory.previewMemory")
  ))))), /* @__PURE__ */ React.createElement("div", { className: "columns is-centered is-mobile" }, /* @__PURE__ */ React.createElement("div", { className: "column is-12-mobile is-8-tablet memories-new__legal-text" }, /* @__PURE__ */ React.createElement(Trans, { i18nKey: "legal.memory" }, /* @__PURE__ */ React.createElement("a", { href: t("legal.termsOfUse"), target: "_blank" }), /* @__PURE__ */ React.createElement("a", { href: t("legal.privacyPolicy"), target: "_blank" })))), /* @__PURE__ */ React.createElement(
    "div",
    {
      className: classNames("memories-new__memory-display-modal-wrapper", {
        "is-hidden": !isModalVisible
      })
    },
    /* @__PURE__ */ React.createElement(Modal, { isVisible: isModalVisible, noPadding: true }, /* @__PURE__ */ React.createElement(
      MemoryDisplay,
      {
        isSubmitDisabled: false,
        memory: form,
        projectUser,
        previewImages,
        onEditClick: closeModal,
        onSubmitClick: handleSubmitForm,
        user,
        isSubmittingNewMemory
      }
    ))
  ), /* @__PURE__ */ React.createElement(
    MemoriesAddLifeThreadModal,
    {
      isVisible: isAddLifeThreadModalVisible,
      lifeThreadName: newLifeThreadName,
      onAddLifeThread,
      onChangeLifeThread,
      onClose: onAddLifeThreadModalOnClose
    }
  ), /* @__PURE__ */ React.createElement(Modal, { size: "small", isVisible: isConfirmingClearMemory }, /* @__PURE__ */ React.createElement(
    ConfirmationModal,
    {
      cancelText: "Cancel",
      closeText: "Close",
      confirmText: "Restart Memory",
      onClose: () => setIsConfirmingClearMemory(false),
      onConfirm: handleClearCachedMemory,
      subtitle: "Once restarted, this memory cannot be recovered.",
      title: "Are you sure?"
    }
  )));
};
export { MemoriesNew as default, submitEnqueuedMemories };
